import React, { useEffect, useState } from "react"
import Sidebar from "../../components/aboutPage/sidebar"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import Order from "../../components/common/order"
import useScheme from "../../hooks/use-scheme"
import { Helmet } from "react-helmet"

const BlockColor = css`
  background-color: #e6e6e6;
`

const Heading = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  margin: 1.1rem;
  ${props => props.theme.tablet} {
    font-size: 1rem;
    margin: 1.1rem 0;
  }
`

const Scheme = styled.div`
  display: flex;
  margin: 4rem 0 1rem 0;
  width: 100%;
  color: black;
`
const SchemeWrapper = styled.div`
  ${props => props.theme.tablet} {
    overflow: auto;
  }
`

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 1.5rem;
  &:last-of-type {
    margin-right: 0rem;
  }
`

const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 20rem;
  margin-bottom: 1rem;
  span {
    font-size: 1.2rem;
  }
`

const SmallBlock = styled.div`
  position: relative;
  padding: 1rem;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 50%;
  ${BlockColor}
  margin-bottom: 1rem;
  &:last-of-type {
    margin-bottom: 0rem;
  }
`

const BigBlock = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 100%;
  ${BlockColor}
  padding: 1rem;
`

const BigBlockColored = styled(BigBlock)`
  background-color: ${props => props.theme.colors.footer};
`

const AdditionalText = styled.div`
  position: absolute;
  top: -22%;
  width: 230%;
  font-size: 0.9rem;
  border-bottom: 1px solid;
  padding: 0 1rem 0.3rem 1rem;
  &:after {
    content: "";
    transform: rotate(90deg);
    width: 4%;
    border-bottom: solid 1px black;
    position: absolute;
    left: -2%;
    top: 115%;
    z-index: 1;
  }

  &:before {
    content: "";
    transform: rotate(90deg);
    width: 4%;
    border-bottom: solid 1px black;
    position: absolute;
    right: -2%;
    top: 115%;
    z-index: 1;
  }
`

const NotationWrapper = styled.div`
  ul {
    padding-inline-start: 15px;
  }
  li {
    margin-bottom: 1rem;
    font-size: 0.8rem;
    color: black;
  }
`

const Line = css`
  &:before {
    content: "";
    width: 13%;
    border-bottom: solid 1px black;
    position: absolute;
    top: 50%;
    z-index: 1;
    transform: translateY(-50%);
  }
`

const LineBefore = css`
  &:before {
    left: -13%;
  }
`

const Arrow = styled("i")`
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  position: absolute;
  top: 49%;
  z-index: 1;
  transform: rotate(-45deg) translateY(-35%);
`

const ArrowAfter = styled(Arrow)`
  right: -14%;
`

const TextColoredBlock = styled.span`
  color: white;
`

const ArrowCenter = styled(Arrow)`
  right: -15%;
  top: 50%;
  transform: rotate(-45deg) translateY(-65%);
`
const ArrowBefore = styled(Arrow)`
  left: -3%;
`

const LineAfter = css`
  &:before {
    right: -13%;
  }
`
const SmallBlockWithArrowAfter = styled(SmallBlock)`
  ${Line}
  ${LineAfter}
`

const SmallBlockWithArrowBefore = styled(SmallBlock)`
  ${Line}
  ${LineBefore}
`

const BigBlockWithArrowBeforeColored = styled(BigBlockColored)`
  ${Line}
  ${LineBefore}
`

const About = props => {
  const data = useScheme()
  const [order, setOrder] = useState([])

  useEffect(() => {
    const parseHtmlToList = html => {
      const div = document.createElement("div")
      div.innerHTML = html
      const htmlList = Array.prototype.slice.call(
        div.getElementsByTagName("li")
      )
      return htmlList.map(li => li.innerHTML)
    }

    const list = parseHtmlToList(data.content)
    const newOrder = list.map(el => {
      return { q: el, a: "" }
    })

    setOrder([...newOrder])
  }, [data.content])

  return (
    <Sidebar>
      <Helmet>
        <title>Схема взаимодействия</title>
        <meta
          name="description"
          content="Отчетные документы Фонд защиты прав граждан -  участников строительства ПК"
        />
      </Helmet>
      <h1>Схема взаимодействия</h1>
      <SchemeWrapper>
        <Scheme>
          <SectionWrapper>
            <BlockWrapper>
              <SmallBlockWithArrowAfter>
                <ArrowAfter />
                <span>Федеральный бюджет</span>
              </SmallBlockWithArrowAfter>
              <SmallBlockWithArrowAfter>
                <ArrowAfter />
                <span>Краевой бюджет</span>
              </SmallBlockWithArrowAfter>
            </BlockWrapper>
            <NotationWrapper>
              <ul>
                <li>Финансирование (имущественный взнос)</li>
              </ul>
            </NotationWrapper>
          </SectionWrapper>
          <SectionWrapper>
            <BlockWrapper>
              <BigBlock>
                <ArrowCenter />
                <span>Федеральный фонд</span>
              </BigBlock>
            </BlockWrapper>
            <NotationWrapper>
              <ul>
                <li>Мониторинг и инициирование банкротства застройщиков;</li>
                <li>Аккредитация конкурсных управляющих;</li>
                <li>Финансирование и контроль;</li>
              </ul>
            </NotationWrapper>
          </SectionWrapper>
          <SectionWrapper>
            <BlockWrapper>
              <BigBlockWithArrowBeforeColored>
                <AdditionalText>
                  Банковское сопровождение уполномоченного банка в сфере
                  жилищного строительства
                </AdditionalText>
                <TextColoredBlock>Региональный фонд</TextColoredBlock>
              </BigBlockWithArrowBeforeColored>
            </BlockWrapper>
            <NotationWrapper>
              <ul>
                <li>
                  Получение из банкротства прав на земельные участки и объекты
                  незавершенного строительства, государственная регистрация
                  перехода прав;
                </li>
                <li>
                  Организация завершения строительства домов и объектов
                  социальной инфраструктуры;
                </li>
                <li>
                  Передача законченных строительством объектов участникам
                  строительства
                </li>
              </ul>
            </NotationWrapper>
          </SectionWrapper>
          <SectionWrapper>
            <BlockWrapper>
              <SmallBlockWithArrowBefore>
                <ArrowBefore />
                <span>Технический заказчик</span>
              </SmallBlockWithArrowBefore>
              <SmallBlockWithArrowBefore>
                <ArrowBefore />
                <span>Генеральный Подрядчик</span>
              </SmallBlockWithArrowBefore>
            </BlockWrapper>
            <NotationWrapper>
              <ul>
                <li>Строительство;</li>
                <li>Привлечение субподрядчиков на отдельные виды работ;</li>
                <li>
                  Контроль за ходом строительства и качеством выполняемых работ
                </li>
              </ul>
            </NotationWrapper>
          </SectionWrapper>
        </Scheme>
      </SchemeWrapper>
      <div>
        <Heading>
          Последовательность действий при принятии решения Федеральным Фондом по
          достройке проблемных объектов.
        </Heading>
        <Order list={order} startingIndex={1} />
      </div>
    </Sidebar>
  )
}

export default About
