import React from "react"
import Collapsible from "react-collapsible"
import styled from "@emotion/styled"

const AnswerWrapper = styled.div`
  padding: 1.5rem 0 1.5rem 1rem;
  font-size: 0.9rem;
  color: ${props => props.theme.colors.secondary};
  overflow-wrap: anywhere;
  p {
    padding-bottom: 1rem;
  }
  div {
    overflow-wrap: anywhere;
  }
  div {
    overflow-wrap: anywhere;
  }
`
const QuestionWrapper = styled.div`
  padding: 0.5rem 0 0.5rem 1rem;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: ${props => (props.clickable ? "cursor" : "pointer")};
  span {
    border-bottom: ${props =>
      props.clickable ? "none" : "1px dashed #77798c"};
    font-size: 1.1rem;
    line-height: 1.2rem;
    &:hover {
      color: ${props => props.theme.colors.activeLink};
    }
  }
`

const QaWrapper = styled.div`
  display: flex;
  padding: 0 0 1rem 0;
  position: relative;
  font-size: 1.5rem;
  &:last-of-type {
    &:before {
      width: 0px;
    }
  }
  &:before {
    content: "";
    position: absolute;
    left: 20px;
    bottom: 0;
    height: calc(100% - 40px);
    width: 1px;
    z-index: 0;
    background-color: ${props => props.theme.colors.footer};
  }
`

const IndexWrapper = styled.div`
  font-family: "Times New Roman", Times, serif, BlinkMacSystemFont, "Segoe UI",
    Roboto, Helvetica, Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  height: 40px;
  min-width: 40px;
  border-radius: 100px;
  color: ${props => props.theme.colors.footerFont};
  background-color: ${props =>
    props.done ? `#a9d472` : `${props.theme.colors.footer}`};
`

const Order = ({ list, startingIndex }) => {
  const order = list.map((question, index) => (
    <QaWrapper key={question.q}>
      <IndexWrapper done={question.done}>{index + startingIndex}</IndexWrapper>
      <Collapsible
        triggerDisabled={question.a ? false : true}
        trigger={
          <QuestionWrapper clickable={question.a ? false : true}>
            <span>{question.q} </span>
          </QuestionWrapper>
        }
      >
        <AnswerWrapper>{question.a}</AnswerWrapper>
      </Collapsible>
    </QaWrapper>
  ))

  return [order]
}

export default Order
